import AroggaAPI from '@/services/apis/AroggaAPI';
import { API_ACCOUNT } from '@/services/apis/apiConstrant';
import cls from 'classnames';
import { useState } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import styles from './upload-button.module.scss';
import { useAlert } from '@/contexts/AlertProvider';
type ApiResponse = {
	data: any;
	status: 'success' | 'fail';
	message?: string;
};

interface UploadPrescriptionButtonProps {
	addToCart?: number;
	className?: string;
	onSuccess?: (message?: string) => void;
	setLoading?: (loading: boolean) => void;
	style?: React.CSSProperties;
	showLabel?: boolean;
	iconStyle?: React.CSSProperties;
	icon?: React.ReactNode;
	onError?: (message?: string) => void;
}

const UploadPrescriptionButton = ({
	addToCart = 0,
	className,
	onSuccess,
	setLoading,
	style,
	showLabel = true,
	iconStyle,
	icon,
	onError
}: UploadPrescriptionButtonProps) => {
	const [uploadLoading, setUploadLoading] = useState(false);
	const alert = useAlert();
	const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLoading?.(true);
		setUploadLoading(true);
		const files = e.target.files;
		if (files && files.length > 0) {
			let formData = {};
			const processFile = (file: File, index: number): Promise<void> => {
				return new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onloadend = function () {
						const base64 = reader.result as string;
						formData = {
							...formData,
							[`attachedFiles_up_s3key[${index}][title]`]: file.name,
							[`attachedFiles_up_s3key[${index}][src]`]: base64
						};

						resolve();
					};

					reader.onerror = () => reject(new Error('Failed to read file'));

					reader.readAsDataURL(file);
				});
			};

			const uploadFiles = async () => {
				setUploadLoading(true);
				const promises = Array.from(files).map((file, index) => processFile(file, index));
				await Promise.all(promises);
				const { data, status, message } = await AroggaAPI.post(
					API_ACCOUNT.PRESCRIPTIONS + `?addToCart=${addToCart}`,
					formData
				);

				if (status === 'success') {
					onSuccess?.(message);
					setLoading?.(false);
					setUploadLoading(false);
					alert.success({
						message: message,
						provider: 'toast'
					});
				} else {
					onError?.(message);
					setLoading?.(false);
					setUploadLoading(false);
					alert.error({
						title: 'Error',
						message: message
					});
				}
			};

			uploadFiles().catch((err) => {
				setLoading?.(false);
				setUploadLoading(false);
			});
		}
	};

	return (
		<label className={cls(styles.upload_prescription, className)} style={style}>
			<div className={styles.upload_prescription__icon} style={iconStyle}>
				{uploadLoading ? (
					<RotatingLines
						strokeColor='#0E7673'
						strokeWidth='5'
						animationDuration='0.75'
						width='96'
						visible={true}
					/>
				) : icon ? (
					icon
				) : (
					<svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48' fill='none'>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M12.7921 0.666992H34.5621C42.4955 0.666992 47.0221 5.14699 47.0221 13.1037V34.897C47.0221 42.807 42.5188 47.3337 34.5855 47.3337H12.7921C4.83547 47.3337 0.355469 42.807 0.355469 34.897V13.1037C0.355469 5.14699 4.83547 0.666992 12.7921 0.666992ZM25.6021 25.937H32.2288C33.3021 25.9137 34.1655 25.0503 34.1655 23.977C34.1655 22.9037 33.3021 22.0403 32.2288 22.0403H25.6021V15.4603C25.6021 14.387 24.7388 13.5237 23.6655 13.5237C22.5921 13.5237 21.7288 14.387 21.7288 15.4603V22.0403H15.1255C14.6121 22.0403 14.1221 22.2503 13.7488 22.6003C13.3988 22.9737 13.1888 23.4613 13.1888 23.977C13.1888 25.0503 14.0521 25.9137 15.1255 25.937H21.7288V32.5403C21.7288 33.6137 22.5921 34.477 23.6655 34.477C24.7388 34.477 25.6021 33.6137 25.6021 32.5403V25.937Z'
							fill='#0E7673'
						/>
					</svg>
				)}
			</div>
			{showLabel && (
				<div className={styles.upload_prescription__text}>
					<span>Upload Prescription</span>
					<small>Add Upto 5 Prescriptions</small>
				</div>
			)}
			<input
				type='file'
				multiple
				accept='image/*'
				name='file'
				style={{ display: 'none' }}
				onChange={handleUpload}
			/>
		</label>
	);
};

export default UploadPrescriptionButton;
